// This import loads the firebase namespace.
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/functions';

// dev / local
const fb = firebase.initializeApp({
    apiKey: "AIzaSyB0MDWXWUQh1F074i26NK17ibz3XLLEo2I",
    authDomain: "entry-level-software-jobs.firebaseapp.com",
    databaseURL: "https://entry-level-software-jobs.firebaseio.com",
    projectId: "entry-level-software-jobs",
    storageBucket: "entry-level-software-jobs.appspot.com",
    messagingSenderId: "281052696448",
    appId: "1:281052696448:web:d599697c10b419e03ebb65",
    measurementId: "G-FQ6EKRNTY5"
  });

const db = firebase.firestore();

export async function getAllJobs() {
  const allJobs = [];

    const snapshot = await db.collection('sources').get();
    snapshot.docs.forEach(source => {
      const sourceJobs = source.data().jobs;
      allJobs.push(...sourceJobs);
    });

    return allJobs.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    })
    
}