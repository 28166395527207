export const stateNames = [
    'Alabama','Alaska','American Samoa','Arizona','Arkansas',
    'California','Colorado','Connecticut','Delaware','District of Columbia',
    'Federated States of Micronesia','Florida','Georgia','Guam','Hawaii',
    'Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana',
    'Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota',
    'Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey',
    'New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands',
    'Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island',
    'South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont',
    'Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'
];

export const stateAbbrevs = [
    "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC",  
    "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA",  
    "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE",  
    "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC",  
    "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"
]

// common us cities
export const USCityNames = [
    'Austin', 'San Francisco', 'Los Angeles', 'Denver', 'Houston',
    'Portland', 'Seattle', 'Dallas', 'Boston', 'D.C.', 'Baltimore',
    'Atlanta', 'Chicago', 'St. Louis', 'St Louis', 'Miami', 'Orlando',
    'Nashville', 'New Orleans'
]
