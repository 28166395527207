import React from 'react';
import './App.css';
import {getAllJobs} from './firebase';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Jobs from './Jobs';

async function fetchJobs(setJobs, setLoading, setError) {
  try {
    const jobs = await getAllJobs();
    setJobs(jobs);
    setLoading(false);
  } catch(e) {
    setError(true);
    setLoading(false);
  }
}


function App() {

  const [jobList, updateJobs] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    fetchJobs(updateJobs, setLoading, setError);
  }, [])

  return (
    <div className="App">
      <div style={{display: 'flex', minHeight: '100vh'}}>
        <div style={{width: '80%', padding: '40px', backgroundColor: '#383e3f'}}>
          <Typography variant="h4" component="h1" style={{fontFamily: 'Courier New', fontWeight: 'bold'}}>
              Entry Level Software Jobs
          </Typography>
          {error && <Typography variant="h6" color="error">Couldn't get jobs. Please refresh.</Typography>}
          {loading ? 
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src="/loader.svg" /></div> : 
            <Jobs jobs={jobList} />}
        </div>
        <div style={{width: '20%', backgroundColor: '#4da2b7', minHeight: '100%', position: 'relative'}}>
          <img className="sitting-guy" style={{position: 'fixed', bottom: '5%', right: '5%'}} src="/sitting-8.svg" />
        </div>
      </div>
    </div>
  );
}

export default App;
