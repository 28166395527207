import {stateNames, stateAbbrevs, USCityNames} from './constants';

function jobIsRemote(job) {
    return job.location.toLowerCase().includes('remote') ||
    job.location.toLowerCase().includes('anywhere')
}

function jobIsInUS(job) {
    console.log({job});
    return stateNames.some(stateName => 
            job.location.toLowerCase().includes(stateName.toLowerCase())
        ) ||
        stateAbbrevs.some(stateAbbrev => {
            const abbrev = new RegExp(`\\b${stateAbbrev}\\b`, 'gi');
            return abbrev.test(job.location);
        }) ||
        USCityNames.some(cityName => {
            return job.location.toLowerCase().includes(cityName.toLowerCase())
        })
}

function jobIsNotInUS(job) {
    return stateNames.every(state => 
            !job.location.toLowerCase().includes(state.toLowerCase())
        ) &&
        stateAbbrevs.every(stateAbbrev => {
            const abbrev = new RegExp(`\\b${stateAbbrev}\\b`, 'gi');
            return !abbrev.test(job.location);
        }) &&
        USCityNames.every(cityName => {
            return !job.location.toLowerCase().includes(cityName.toLowerCase())
        }) &&
        !jobIsRemote(job)
    }

export const filterJobsByLocation = (jobs, location) => {
    switch(location) {
        case 'all':
            return jobs;
        case 'us':
            return jobs.filter(jobIsInUS)
        case 'intl':
            return jobs.filter(jobIsNotInUS);
        case 'remote':
            return jobs.filter(job => job.location.toLowerCase().includes('remote'))
        default:
            return jobs;
    }
}