import React from 'react'
import Job from './Job';
import JobModal from './JobModal'
import Paper from '@material-ui/core/Paper';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';


import {filterJobsByLocation} from './utils';

export default function Jobs({jobs}) {

    // location radio section
    const [location, setLocation] = React.useState('all');
    function handleLocationChange(event) {
        setLocation(event.target.value);
    }

    // filter jobs based on location
    jobs = filterJobsByLocation(jobs, location);

    // modal
    const [open, setOpen] = React.useState(false);
    const [selectedJob, selectJob] = React.useState({});
    function handleClickOpen() {
      setOpen(true);
    }  
    function handleClose() {
      setOpen(false);
    }
    // pagination
    const numJobs = jobs.length;
    const numPages = Math.ceil(numJobs / 50);
    const [activeStep, setActiveStep] = React.useState(0);
    const jobsOnPage = jobs.slice(activeStep * 50, (activeStep * 50) + 50);

    // step == 0, show 0-49
    // step == 1, show 50 - 99

    function scrollToTop () {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 8);
        }
      };

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        scrollToTop();
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        scrollToTop();
    }    

    return (
        <div className="jobs">
            <JobModal open={open} job={selectedJob} handleClose={handleClose} />
            <Typography variant="h6" component="h2">
                Found {numJobs} Jobs
            </Typography>
            <Paper className="location-radio-container">
                <FormControl component="fieldset">
                    <FormLabel component="legend">Location</FormLabel>
                    <RadioGroup 
                        name="location" 
                        value={location} 
                        onChange={handleLocationChange} 
                        row
                    >
                        <FormControlLabel
                            className="location-radio-item"
                            value="all"
                            control={<Radio color="primary" />}
                            label="All"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className="location-radio-item"
                            value="us"
                            control={<Radio color="primary" />}
                            label="U.S."
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className="location-radio-item"
                            value="intl"
                            control={<Radio color="primary" />}
                            label="International"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            className="location-radio-item"
                            value="remote"
                            control={<Radio color="primary" />}
                            label="Remote"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </FormControl>
            </Paper>
            {
                jobsOnPage.map(
                    (job, i) => <Job key={i} job={job} onClick={() => {
                        handleClickOpen();
                        selectJob(job)
                    }} />
                )
            }
            <div>
                Page {activeStep + 1} of {numPages}
            </div>
            <MobileStepper
                variant="progress"
                steps={numPages}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === numPages - 1}>
                    Next
                    <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                    Back
                    </Button>
                }
            />

        </div>
    )
}